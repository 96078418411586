@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:ital@1&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}


.description,
.intro {
  font-family: 'Crimson Pro', serif;
  font-style: italic;
}

.main {
  min-height: 100vh;
}

.start {
  color: white;
  cursor: pointer;
}


.page {
  background-size: cover;
  background-color: #000;
}

.link-back,
.link-fwd {
  color: #fff;
  cursor: pointer;
  padding: 2rem;
  position: fixed;
  bottom: 10px;
  font-size: 18px;
  font-family: 'Crimson Pro', serif;
  font-style: italic;
  z-index: 1000;
}

.link-fwd {

  right: 20px;
}

.link-back {
  left: 20px;
}

/* hide scrollbar but allow scrolling */
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  display: block;
}

.ReactModal__Overlay .ReactModal__Overlay--after-open {
  inset: 1px;
}

body {
  background: #000;
}

.description {
  position: fixed;
  color: #fff;
  font-size: 2.4rem;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  text-align: left;
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / .1);
  padding-left: 29%;
  line-height: 3.2rem;
  z-index: 500;
}

.description > p {
  opacity: .4;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.section {

  height: 100vh;
  width: 100vw;
  background-color: #000;
}

.bg-image {
  height: 100vh;
  width: 100vw;
}


button {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
  color: #fff;
}

button:hover {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
}

button:active {
  outline: none;
  border: none;
}



.start {
  color: white;
  cursor: pointer;
}

button {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
}

button:hover {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
}

button:active {
  outline: none;
  border: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 3000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.title-image {
  max-width: 60%;
}


.intro {
  background: url(/static/media/tides-front-bg.b5342af0.jpg) no-repeat center center fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}

.logo > img {
  max-width: 400px;
}

.center {
  color: white;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.author,
.logo {
  flex: 1 1;
}

.logo {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.author {
  color: #D5BDB9;
  padding-bottom: 1rem;
}

.author > p {
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 0.7rem;
}

.details {
  color: #f3f3f3;

  font-size: 19px;
}

a {
  color: #D5BDB9;
  font-weight: bold;
}

.right {
  margin: 0 1rem;
  padding-top: 20rem;
}

