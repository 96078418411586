.intro {
  background: url("./img/titles/tides-front-bg.jpg") no-repeat center center fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo > img {
  max-width: 400px;
}

.center {
  color: white;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.author,
.logo {
  flex: 1;
}

.logo {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.author {
  color: #D5BDB9;
  padding-bottom: 1rem;
}

.author > p {
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 0.7rem;
}

.details {
  color: #f3f3f3;

  font-size: 19px;
}

a {
  color: #D5BDB9;
  font-weight: bold;
}

.right {
  margin: 0 1rem;
  padding-top: 20rem;
}
