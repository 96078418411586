.page {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
}

.link-back,
.link-fwd {
  color: #fff;
  cursor: pointer;
  padding: 2rem;
  position: fixed;
  bottom: 10px;
  font-size: 18px;
  font-family: 'Crimson Pro', serif;
  font-style: italic;
  z-index: 1000;
}

.link-fwd {

  right: 20px;
}

.link-back {
  left: 20px;
}

/* hide scrollbar but allow scrolling */
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  display: block;
}

.ReactModal__Overlay .ReactModal__Overlay--after-open {
  inset: 1px;
}

body {
  background: #000;
}

.description {
  position: fixed;
  color: #fff;
  font-size: 2.4rem;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  text-align: left;
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / .1);
  padding-left: 29%;
  line-height: 3.2rem;
  z-index: 500;
}

.description > p {
  opacity: .4;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
