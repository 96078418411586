@import url( 'https://fonts.googleapis.com/css2?family=Crimson+Pro:ital@1&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}


.description,
.intro {
  font-family: 'Crimson Pro', serif;
  font-style: italic;
}

.main {
  min-height: 100vh;
}

.start {
  color: white;
  cursor: pointer;
}

