.section {

  height: 100vh;
  width: 100vw;
  background-color: #000;
}

.bg-image {
  height: 100vh;
  width: 100vw;
}


button {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
  color: #fff;
}

button:hover {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
}

button:active {
  outline: none;
  border: none;
}



.start {
  color: white;
  cursor: pointer;
}
