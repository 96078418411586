button {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
}

button:hover {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
}

button:active {
  outline: none;
  border: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 3000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.title-image {
  max-width: 60%;
}

